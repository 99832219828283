import React from "react";
import Select from "react-select";

export const RichSelectOptions = React.forwardRef(
	(
		{
			options,
			value,
			onChange = val => {},
			disabled = false,
			minWidth = 120,
			minHeight = 42,
			fontSize,
			onChangeLoseFocus = false,
			...props
		},
		ref,
	) => {
		const onValueChange = v => {
			if (Array.isArray(v)) {
				onChange(v.map(item => item.value));
				return;
			}
			if (!!ref && !!ref.current && !!onChangeLoseFocus) {
				ref.current.blur();
			}
			onChange(v?.value);
			if (!!onChangeLoseFocus) {
				props?.onBlur(value);
			}
		};

		const styles = {
			menuPortal: base => ({ ...base, zIndex: 9999 }),
			control: (base, state) => {
				return {
					...base,
					minHeight,
					minWidth,
					fontSize,
					transition: "all .2s ease",
					paddingLeft: 0,
					paddingRight: 0,
					cursor: disabled ? "not-allowed" : "pointer",
					border: state.menuIsOpen
						? "solid 1px #64748b"
						: "solid 1px rgb(209, 213, 219)",
					boxShadow: state.menuIsOpen
						? "0 0 0 1px #64748b"
						: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
					backgroundColor: "#fff",
					"&:hover": {
						border: state.menuIsOpen
							? "solid 1px #64748b"
							: "solid 1px rgb(209, 213, 219)",
					},
				};
			},
			multiValue: provided => ({
				...provided,
				borderRadius: "8px",
				margin: "3px 5px",
				padding: "0px 2px",
			}),
			input: provided => ({
				...provided,
				margin: "0px",
			}),
			clearIndicator: provided => ({
				...provided,
				padding: 0,
				transform: "scale(0.75)",
			}),
			indicatorsContainer: provided => ({
				...provided,
				height: "auto",
				paddingTop: 0,
				paddingBottom: 0,
				display: "flex",
				borderRight: "none",
			}),
			indicatorSeparator: provided => ({
				display: "none",
			}),
			dropdownIndicator: provided => ({
				...provided,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 4,
				paddingRight: 4,
				transform: "scale(0.75)",
			}),
			menu: provided => ({
				...provided,
				minWidth: 80,
			}),
			option: (base, { data, isDisabled, isFocused, isSelected }) => {
				return {
					...base,
					cursor: "pointer",
					height: "auto",
					minHeight: 0,
					padding: "6px 12px",
					fontWeight: 400,
					fontSize,
					background: isDisabled
						? "#fff"
						: isFocused
						? "rgba(0,0,0,0.025)"
						: "none",
					color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
					"&:active": {
						background: isDisabled
							? "#fff"
							: isFocused
							? "rgba(0,0,0,0.025)"
							: "none",
						color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
					},
				};
			},
		};

		const currentValue = Array.isArray(value)
			? options.filter(opt => value.indexOf(opt.value) !== -1)
			: options.find(opt => opt.value === value);

		return (
			<div className="rich-select">
				<Select
					menuPortalTarget={document.body}
					placeholder="בחירה..."
					isClearable={false}
					isDisabled={disabled}
					options={options}
					onChange={onValueChange}
					styles={styles}
					noOptionsMessage={() => "לא נמצאו פריטים."}
					value={currentValue}
					{...props}
					ref={ref}
				/>
			</div>
		);
	},
);
RichSelectOptions.displayName = "RichSelectOptions";
