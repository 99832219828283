import { Disclosure } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import React, { createRef, useMemo } from "react";

const ONE_ANSWER_OPTIONS = ["no side effect", "no positive effect", "no smell"];
const ONE_ANSWER_QUESTIONS = [
	"side effects",
	"positive effects",
	"taste and scent",
];
const order = {
	"very light": 1,
	light: 2,
	dark: 3,
	"very dark": 4,
};

const ReviewStep = ({
	questions,
	group,
	watch,
	register,
	setValue,
	setStep,
	nextStep,
	prevStep,
}) => {
	const refs = useMemo(() => {
		return (
			questions.map(() => {
				return createRef();
			}) ?? []
		);
	}, []);

	const isOneAnswer = (question, option) => {
		// Check if answer is in one_answer_options array and disable other options but the one_answer_options one
		return (
			watch(question)?.some(word => ONE_ANSWER_OPTIONS.includes(word)) &&
			!ONE_ANSWER_OPTIONS.includes(option)
		);
	};

	const isDisabled = (question, option) => {
		// Check if current answers are more (not possible but jic) or equal to max options and the current selected answers are not disabled
		// + isOneAnswer
		return (
			(watch(`${question.eng_name}`)?.length >= question.max_options &&
				!watch(`${question.eng_name}`).includes(option.eng_name)) ||
			isOneAnswer(question.eng_name, option.eng_name)
		);
	};

	const handleOnChange = (e, question, option) => {
		const selectedOptions = watch(question);
		if (
			ONE_ANSWER_QUESTIONS.includes(question) &&
			ONE_ANSWER_OPTIONS.includes(e.target.value) &&
			e.target.checked
		) {
			setValue(`${question}`, [option]);
		} else if (e.target.checked) {
			setValue(
				`${question}`,
				selectedOptions ? [...selectedOptions, option] : [option],
			);
		} else {
			setValue(
				`${question}`,
				selectedOptions.filter(selectedOption => selectedOption !== option),
			);
		}
	};

	function handleClosingOthers(id) {
		const otherRefs = refs.filter(ref => {
			return ref.current?.getAttribute("data-id") !== id;
		});
		otherRefs.forEach(ref => {
			const isOpen = ref.current?.getAttribute("data-open") === "true";
			if (isOpen) {
				ref.current?.click();
			}
		});
	}

	function handleOpenNextDisclosure(id, i) {
		if (i === refs.length - 1) {
			setStep(nextStep);
		} else {
			const nextRefId = (questions[i+1].question.id).toString();
			const nextRef = refs.find(ref => {
				return ref.current?.getAttribute("data-id") === nextRefId;
			});
			handleClosingOthers(id);

			if (!nextRef) return;
			nextRef.current?.click();
		}
	}

	function handleOpenLastDisclosure(id, i) {
		if (i === 0 && prevStep) {
			setStep(prevStep);
		} else {
			if (Number(id) === 0) return;
			const lastRefId = (+id - 1).toString();
			const lastRef = refs.find(ref => {
				return ref.current?.getAttribute("data-id") === lastRefId;
			});
			handleClosingOthers(id);

			if (!lastRef) return;
			lastRef.current?.click();
		}
	}

	function findHebNames(question, engNames) {
		const newQuestion = questions.find(
			questionAll => questionAll.question.id === question.id,
		);
		if (newQuestion) {
			const hebNames = engNames.map(engName => {
				const option = newQuestion?.options.find(
					opt => opt.eng_name === engName,
				);
				return option ? option.heb_name : null;
			});
			return (
				<div>
					{hebNames.map((name, i) => (
						<span key={name}>
							{name}
							{i === hebNames.length - 1 ? "" : ", "}
						</span>
					))}
				</div>
			);
		}

		return null;
	}
	return (
		<div className="flex flex-col gap-2">
			{questions.map(({ question, options }, i) => {
				if (
					group === "about-product-characteristics" &&
					question.eng_name === "shade"
				) {
					options = options.sort(
						(a, b) => order[a.eng_name] - order[b.eng_name],
					);
				}
				return (
					<Disclosure key={`${question.id}-${i}`} defaultOpen={i === 0}>
						{({ open, close }) => (
							<div className={`flex flex-col rounded-lg shadow-sm`}>
								<Disclosure.Button
									ref={refs[i]}
									data-id={question.id}
									data-open={open}
									className={`flex w-full justify-between items-center ${
										open ? "rounded-t-lg" : "rounded-lg"
									} bg-white px-4 py-2  `}
									onClick={e => {
										handleClosingOthers(question.id);
									}}
								>
									<div
										className={`font-semibold flex gap-2 items-center text-black/70 min-w-[130px]`}
									>
										<span className="flex items-center justify-center bg-gray-200 rounded-full w-[22px] h-[22px] text-[#414B60] text-sm">
											{!open && watch(`${question.eng_name}`)?.length ? (
												<CheckIcon className="w-4 h-4" />
											) : (
												i + 1
											)}
										</span>
										<span>{question.heb_name}</span>
									</div>
									<div className="flex gap-2">
										{!open && watch(`${question.eng_name}`) && (
											<div>
												<span>
													{findHebNames(
														question,
														watch(`${question.eng_name}`),
													)}
												</span>
											</div>
										)}
										{!open && watch(`${question.eng_name}`)?.length ? (
											<div className="flex items-center">
												<PencilIcon className="w-4 h-4 text-black/70" />
											</div>
										) : null}
										{open && (
											<div className="">
												<span className="font-semibold">
													{watch(`${question.eng_name}`)?.length || 0}
												</span>
												<span>/</span>
												<span>{question.max_options}</span>
											</div>
										)}
										{open && <div>({question.max_options} אפשרויות בחירה)</div>}
									</div>
								</Disclosure.Button>
								<Disclosure.Panel className="px-4 bg-white rounded-b-lg pb-2 text-gray-500 flex flex-col gap-1 ">
									<div className="w-full grid grid-cols-12 border-t border-t-[#414B60]/20 pt-2 gap-2">
										{options.map(option => (
											<div key={option.id} className="col-span-6 md:col-span-4">
												<div className="flex gap-1 items-center">
													<input
														type="checkbox"
														className="rounded disabled:opacity-50"
														{...register(`${question.eng_name}`)}
														checked={(
															watch(`${question.eng_name}`) || []
														).includes(option?.eng_name)}
														id={`${question.eng_name}-${option.eng_name}`}
														value={option.eng_name}
														disabled={isDisabled(question, option)}
														onChange={e =>
															handleOnChange(
																e,
																question.eng_name,
																option.eng_name,
															)
														}
													/>
													<label
														htmlFor={`${question.eng_name}-${option.eng_name}`}
														className={`${
															isDisabled(question, option) ? "opacity-50" : ""
														} break-words`}
													>
														{option.heb_name}
													</label>
												</div>
											</div>
										))}
									</div>
									<div className="flex self-end gap-2 mt-2">
										{!(i === 0) && (
											<Button
												onClick={() => handleOpenLastDisclosure(question.id, i)}
											>
												חזור
											</Button>
										)}

										<Button
											primary
											disabled={
												!nextStep && i === questions.length - 1 ? true : false
											}
											onClick={() => handleOpenNextDisclosure(question.id, i)}
										>
											הבא
										</Button>
									</div>
								</Disclosure.Panel>
							</div>
						)}
					</Disclosure>
				);
			})}
		</div>
	);
};

export default ReviewStep;
