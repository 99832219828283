import { InformationCircleIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import FileUploadForm from "components/form/FileUploadForm";
import { RichSelectOptions } from "components/form/RichSelectOptions";
import StarRating from "components/form/StarRating";
import React from "react";
import { Controller } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { IoInformation } from "react-icons/io5";
import { Tooltip } from "react-tippy";
import { classNames } from "util/common";

const OPTIONS = {
	"show-name": "שם פרטי",
	"show-nickname": "שם תצוגה (קבוע)",
	"hide-name": "אנונימי",
};

const ReviewGeneralStep = ({
	setValue,
	watch,
	control,
	register,
	errors,
	isSubmitting,
	claims,
	setStep,
	nextStep,
	isValid,
	trigger,
	userNicknames,
}) => {
	const validateNickname = value => {
		const trimmedValue = value.trim().toLowerCase();
		const isNicknameNotAllowed = userNicknames.some(
			nickname => nickname.trim().toLowerCase() === trimmedValue,
		);

		return (
			!isNicknameNotAllowed ||
			"שם התצוגה כבר תפוס. יש לבחור שם אחר ולנסות שנית."
		);
	};
	const tooltipContent = (
		<div className="text-start">
			<p className="font-bold mb-2">לעזור לקהילה - מדד ג&apos;יין</p>
			<p className="mb-2">
				חדש: מדד ג&apos;יין - מערכת ביקורות מוצרי קנאביס רפואי - האמינה
				והמשוכללת ביותר!
			</p>
			<p>
				מדד ג&apos;יין מאפשר מלבד חוות דעת כללית, גם הזנת אפיון חושי ופיזי של
				המוצר.
			</p>
		</div>
	);
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<label className="text-black/70 font-semibold">
					דירוג:
					<span
						className={classNames(
							"text-jane-400 mr-1 text-sm",
							errors?.rating && "text-red-400",
						)}
					>
						(סעיף חובה)
					</span>
				</label>
				<StarRating control={control} name="rating" />
				{/* {errors?.rating && (
					<span className="text-red-400">נא למלא את כל הפרטים.</span>
				)} */}
			</div>

			<div className="flex flex-col gap-1">
				<label className="text-black/70 font-semibold">
					תיאור:
					<span
						className={classNames(
							"text-jane-400 mr-1 text-sm",
							errors?.content && "text-red-400",
						)}
					>
						(סעיף חובה)
					</span>
				</label>
				<textarea
					{...register("content", {
						required: true,
						minLength: { value: 20, message: "מינימום 20 תווים." },
					})}
					className="w-3/4 rounded-md border-gray-300 resize-none focus:border-gray-300 focus:ring-0"
					cols={2}
					rows={3}
				></textarea>
				{errors?.content && (
					<span className="text-red-400">{errors.content.message}</span>
				)}
			</div>
			<div className="flex flex-col gap-1">
				<span className="text-black/70 font-semibold">
					תמונות:{" "}
					<span className="text-jane-400 text-sm ">
						({watch("images").length}/5)
					</span>
				</span>
				<FileUploadForm setValue={setValue} watch={watch} />
			</div>
			<div className="flex flex-col gap-1">
				<label className="text-black/70 font-semibold">
					פרטיות:
					<span
						className={classNames(
							"text-jane-400 mr-1 text-sm",
							errors?.privacy && "text-red-400",
						)}
					>
						(סעיף חובה)
					</span>
				</label>
				<span>
					<Controller
						control={control}
						name="privacy"
						rules={{
							required: true,
						}}
						render={({ field: { onChange, value } }) => (
							<RichSelectOptions
								className="max-w-[200px]"
								onChange={onChange}
								options={Object.entries(OPTIONS).map(([value, label]) => {
									return { label, value };
								})}
								value={value}
							/>
						)}
					/>
					<span className="text-jane-500 flex items-center gap-1 mt-1">
						{watch("privacy") && <InformationCircleIcon className="h-3 w-3" />}
						<span className="text-sm  leading-none">
							{watch("privacy") == "show-name"
								? "השם האמיתי שלך כפי שמוזן במערכת"
								: watch("privacy") == "show-nickname"
								? "שם כלשהו לבחירתך שילווה אותך באופן קבוע"
								: watch("privacy") == "hide-name"
								? "ללא שום הזדהות שהיא"
								: undefined}
						</span>
					</span>
				</span>
				{watch("privacy") === "show-nickname" && claims.nickname && (
					<span>{claims.nickname}</span>
				)}
				{watch("privacy") === "show-nickname" && !claims.nickname && (
					<>
						<input
							className={`mt-1 block w-3/4 border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-base focus:ring-1 
					${`${
						!!errors?.nickname
							? "border-red-300 focus:ring-red-500 bg-red-50 bg-opacity-50"
							: "border-gray-300 focus:ring-jane-500"
					}`}`}
							{...register("nickname", {
								required: {
									value:
										watch("privacy") === "show-nickname" && !claims.nickname,
									message: "שדה חובה.",
								},
								maxLength: { value: 15, message: "מקסימום 15 תווים." },
								minLength: { value: 3, message: "מינימום 3 תווים." },
								validate: validateNickname,
							})}
						/>
					</>
				)}
				{!!errors?.nickname &&
					watch("privacy") === "show-nickname" &&
					!claims.nickname && (
						<span className="text-red-400">{errors.nickname.message}</span>
					)}
				{/* {errors?.privacy && (
					<span className="text-red-400">נא למלא את כל הפרטים.</span>
				)} */}
			</div>

			<div className="flex justify-end gap-2">
				<Button className="" type="submit" disabled={isSubmitting && !isValid}>
					שליחת ביקורת רגילה
				</Button>

				<Button
					primary
					className="gap-2"
					onClick={() => (isValid ? setStep(nextStep) : trigger())}
				>
					<span>המשך למדד ג&apos;יין</span>
					<span className="hidden lg:inline">
						<Tooltip arrow html={tooltipContent}>
							<FaInfoCircle className="w-4 h-4 text-white" />
						</Tooltip>
					</span>
				</Button>
				<span className="lg:hidden">
					<Tooltip arrow html={tooltipContent}>
						<div className="bg-jane-600 rounded-md p-2">
							<FaInfoCircle className="w-4 h-4 text-white " />
						</div>
					</Tooltip>
				</span>
			</div>
		</div>
	);
};

export default ReviewGeneralStep;
