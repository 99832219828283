import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import Link from "next/link";
import { useActiveTab } from "components/hooks/useActiveTab";
import { TAB } from "util/types";

export default function PageHeader({ title, subtitle, breadcrumbs = [] }) {
	const { activeTab, setActiveTab } = useActiveTab();
	return (
		<div>
			<div>
				<nav className="flex" aria-label="Breadcrumb">
					<ol role="list" className="flex items-center truncate">
						{breadcrumbs.map((breadcrumb, index) => {
							return (
								<Fragment key={index}>
									<li>
										<div className="flex">
											{index > 0 && (
												<ChevronLeftIcon
													className="flex-shrink-0 h-5 w-5 text-gray-400 mx-2"
													aria-hidden="true"
												/>
											)}
											{breadcrumb.path ? (
												<Link href={breadcrumb.path}>
													<a
														className="text-sm font-medium text-gray-500 hover:text-gray-700 whitespace-nowrap"
														onClick={() => {
															if (breadcrumb.activeTab) {
																setActiveTab(breadcrumb.activeTab);
															}
														}}
													>
														{breadcrumb.text}
													</a>
												</Link>
											) : (
												<span className="text-sm font-medium text-gray-500 whitespace-nowrap cursor-default">
													{breadcrumb.text}
												</span>
											)}
										</div>
									</li>
								</Fragment>
							);
						})}
					</ol>
				</nav>
			</div>
			<div className="md:flex md:items-center md:justify-between">
				<div className="flex-1 min-w-0">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
						{title}
					</h2>
					{!!subtitle && (
						<div className="text-gray-600 font-medium">{subtitle}</div>
					)}
				</div>
			</div>
		</div>
	);
}
