import { Button } from "components/form/Button";
import { Table } from "components/tables/Table";
import ProductImage from "components/ui/ProductImage";
import React from "react";
import { dateConvert, productAlt } from "util/common";

const ReviewBatchStep = ({ batches, setStep, nextStep, setBatch, product }) => {
	const columns = [
		{
			Header: <div className="p-1">אצווה</div>,
			accessor: "batch_id",
			id: "batch_id",
			name: "batch_id",
			Cell: props => {
				return (
					<span className="font-semibold lg:pr-3">
						{props.row.original.batch_id !== "noBatch"
							? props.row.original.batch_id
							: "אין מידע"}
					</span>
				);
			},
		},
		{
			Header: <div className="p-1">THC</div>,
			accessor: "batch_thc",
			id: "batch_thc",
			name: "batch_thc",
			Cell: props => {
				return (
					<span className="font-semibold lg:pr-3">
						{!props.row.original.thc ? "-" : `${props.row.original.thc}%`}
					</span>
				);
			},
		},
		{
			Header: <div className="p-1">CBD</div>,
			accessor: "batch_cbd",
			id: "batch_cbd",
			name: "batch_cbd",
			Cell: props => {
				return (
					<span className="font-semibold lg:pr-3">
						{!props.row.original.cbd ? "-" : `${props.row.original.cbd}%`}
					</span>
				);
			},
		},
		{
			Header: <div className="p-1">תוקף</div>,
			accessor: "batch_exp_date",
			id: "batch_exp_date",
			name: "batch_exp_date",
			Cell: props => {
				return (
					<span className="font-semibold lg:pr-3">
						{!props.row.original.product_batch
							? "-"
							: dateConvert(props.row.original.product_batch.expiration_date)}
					</span>
				);
			},
		},
		{
			Header: <div className="p-1">ביקורת</div>,
			accessor: "review",
			id: "review",
			name: "review",
			Cell: props => {
				return (
					<Button
						primary
						onClick={() => {
							setBatch(props.row.original);
							setStep(nextStep);
						}}
					>
						ביקורת
					</Button>
				);
			},
		},
	];
	return (
		<>
			<span className="flex gap-2 mb-2">
				<ProductImage product={product} className="h-20 w-20 rounded-md" />
				<div className="flex flex-col justify-center">
					<p className="font-bold text-xl leading-none mb-1">
						{product.heb_name}
					</p>
					<p className="text-base leading-none">{product.eng_name}</p>
				</div>
			</span>
			<p className="font-semibold mb-1">
				אנא בחר לאיזו אצווה ברצונך להשאיר ביקורת:
			</p>
			<Table columns={columns} items={batches} />
		</>
	);
};

export default ReviewBatchStep;
