import {
	DocumentIcon,
	TruckIcon,
	ShoppingCartIcon,
	DocumentAddIcon,
	DocumentTextIcon,
	CheckCircleIcon,
	CubeIcon,
} from "@heroicons/react/outline";
import { BsBoxSeam } from "react-icons/bs";
import { useResponsive } from "components/hooks/useResponsive";
import Image from "next/image";
import leafIcon from "public/icons/leaf-icon.svg";
import tasteIcon from "public/icons/taste-icon.svg";
import effectsIcon from "public/icons/effects-icon.svg";
import { MdOutlineRateReview } from "react-icons/md";
const steps = [
	{
		title: "כללי",
		step: 1,
		icon: <MdOutlineRateReview className="w-10 h-10" />,
		iconSm: <MdOutlineRateReview className="w-5 h-6" />,
	},
	{
		title: "נראות",
		step: 2,
		icon: <Image src={leafIcon} alt="נראות" />,
		iconSm: <Image src={leafIcon} alt="נראות" />,
	},
	{
		title: "טעם וריח",
		step: 3,
		icon: <Image src={tasteIcon} alt="טעם וריח" />,
		iconSm: <Image src={tasteIcon} alt="טעם וריח" />,
	},
	{
		title: "השפעות",
		step: 4,
		icon: <Image src={effectsIcon} alt="השפעות" />,
		iconSm: <Image src={effectsIcon} alt="השפעות" />,
	},
];

export default function Stepper({ step, setStep, isValid, trigger }) {
	const screen = useResponsive();
	return (
		<div
			className="flex  items-center justify-center w-full text-sm font-medium text-center 
    text-gray-500 rounded-lg"
		>
			<div className="h-[1px] mt-2 w-full bg-jane-900" />

			{steps.map((s, index) => (
				<div
					onClick={() => {
						if (step !== 0 && isValid) {
							setStep(s.step);
						} else {
							trigger();
						}
					}}
					key={index}
					className={`flex items-center ${step !== 0 && "cursor-pointer"} `}
				>
					<div
						className={`flex flex-col items-center gap-1 transition-opacity ${
							index + 1 === step ? "text-jane-900 font-bold" : ""
						}`}
					>
						<span
							className={`h-[24px] ${
								index + 1 === step ? "opacity-100" : "opacity-60"
							}`}
						>
							{s.iconSm}
						</span>
						<div className="flex items-center justify-center">
							<div className="h-[1px] w-[31px] bg-jane-900" />
							<span
								className={`flex items-center justify-center w-5 h-5 text-xs border-[1px] border-jane-900 rounded-full shrink-0  ${
									index + 1 === step && "text-jane-900 font-bold "
								}`}
							>
								{index + 1}
							</span>
							<div className="h-[1px] w-[31px] bg-jane-900" />
						</div>
						<span className="text-xs">{s.title}</span>
					</div>
				</div>
			))}
			<div className="h-[1px] mt-2 w-full bg-jane-900" />
		</div>
	);
}
