import React, { useContext, useEffect, useState } from "react";
import { ModalDialog } from "./ModalDialog";
import { useApi } from "components/hooks/useApi";
import { useForm } from "react-hook-form";
import Stepper from "components/reviews/Stepper";
import ReviewStep from "components/reviews/ReviewStep";
import { AuthContext } from "components/contexts/AuthContext";
import { toastMessage } from "util/common";
import ReviewBatchStep from "components/reviews/ReviewBatchStep";
import ReviewGeneralStep from "components/reviews/ReviewGeneralStep";
import FinalStep from "components/reviews/FinalStep";
import { Button } from "components/form/Button";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ClipboardCheckIcon,
} from "@heroicons/react/outline";

const ReviewModal = ({
	open,
	setOpen = () => {},
	onClose,
	onSubmit: onAfterSubmit = () => {},
	product,
	paramBatch = null,
	setSubmittedReview = () => {},
	setParamBatch = () => {},
	setReviewsByUser = () => {},
	setShouldRedirectNoBatch = () => {},
	batches = [],
}) => {
	const api = useApi();
	const [questions, setQuestions] = useState([]);
	const [userNicknames, setUserNicknames] = useState([]);
	const [step, setStep] = useState(batches.length > 1 && !paramBatch ? 0 : 1);
	const [batch, setBatch] = useState(paramBatch ? paramBatch : null);
	const { claims } = useContext(AuthContext);
	const {
		register,
		watch,
		control,
		handleSubmit,
		getValues,
		setValue,
		setError,
		reset,
		trigger,
		clearErrors,
		formState: { errors, isValid, isDirty, isSubmitting },
	} = useForm({
		mode: "onChange",
		defaultValues: {
			images: [],
		},
	});

	const load = async () => {
		try {
			const res = await api.getReviewQuestions();
			if (res.ok) {
				setQuestions(res.data.questions);
				setUserNicknames(res.data.end_user_nicknames);
			}
		} catch (e) {
			console.warn(e);
		}
	};
	useEffect(() => {
		void load();
	}, []);

	useEffect(() => {
		if (questions.length) {
			reset({
				...questions.reduce((acc, { question }) => {
					acc[question.eng_name] = [];
					return acc;
				}, {}),
				images: [],
				product_batch: null,
			});
		}
	}, [questions]);

	const getQuestionsByGroup = group => {
		return questions
			.filter(({ question }) => question.group === group)
			.sort((a, b) => a.step - b.step);
	};

	const getAnswers = data => {
		const keysToRemove = ["content", "rating", "privacy", "images"];
		const newData = { ...data };
		for (const key of keysToRemove) {
			delete newData[key];
		}
		return newData;
	};

	const onSubmit = async params => {
		const newReview = {
			product_batch: batch?.id || null,
			product: product.id,
			user_id: claims.user_id,
			rating: params.rating,
			content: params.content,
			privacy: params.privacy,
			images: params.images.filter(img => img.image_url),
			answers: getAnswers(params),
		};
		if (params.nickname) {
			newReview.nickname = params.nickname;
		}
		if (claims.nickname && params.privacy === "show-nickname") {
			newReview.nickname = claims.nickname;
		}
		const res = await api.createReview(newReview);
		if (res.ok) {
			setSubmittedReview(true);
			setReviewsByUser(batch?.id ? batch?.id : "noBatch");
			toastMessage("ביקורת נשלחה בהצלחה", "success");
			void api.checkLogin();
			setBatch(null);
			setParamBatch(null);
			setShouldRedirectNoBatch(false);
			setStep(0);
			onClose();
		} else {
			toastMessage("שגיאה בעת שליחת ביקורת", "error");
		}
		onAfterSubmit();
	};

	return (
		<ModalDialog
			isOpen={open}
			title={`ביקורת - ${product.heb_name}${
				batch && batch.batch_id !== "noBatch" ? ` - ${batch.batch_id}` : ""
			}`}
			onClose={() => {
				onClose();
				setBatch(null);
				setParamBatch(null);
				setShouldRedirectNoBatch(false);
				setStep(0);
			}}
			footer={
				step > 1 && (
					<div className="flex gap-2 items-center mr-auto">
						<Button
							onClick={() => {
								setStep(step - 1);
							}}
						>
							<ChevronRightIcon className="w-4 h-4" />
							<span className="whitespace-nowrap leading-none">שלב קודם</span>
						</Button>

						{step !== 4 ? (
							<Button
								primary
								onClick={() => {
									setStep(step + 1);
								}}
							>
								<span className="whitespace-nowrap leading-none">שלב הבא</span>
								<ChevronLeftIcon className="w-4 h-4" />
							</Button>
						) : (
							<Button primary onClick={() => handleSubmit(onSubmit)()}>
								<span className="whitespace-nowrap leading-none">שליחה</span>
								<ClipboardCheckIcon className="w-4 h-4 mr-1" />
							</Button>
						)}
					</div>
				)
			}
			showSubmitButton={false}
			showCancelButton={false}
			maxWidth={600}
		>
			{step !== 0 && (
				<Stepper
					step={step}
					setStep={setStep}
					trigger={trigger}
					isValid={isValid}
				/>
			)}
			<div className={step === 0 ? "mt-2" : "mt-4"}>
				{questions.length ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						{step === 0 && (
							<ReviewBatchStep
								batches={batches}
								setStep={setStep}
								nextStep={1}
								setBatch={setBatch}
								product={product}
							/>
						)}
						{step === 1 && (
							<ReviewGeneralStep
								trigger={trigger}
								setValue={setValue}
								watch={watch}
								control={control}
								register={register}
								errors={errors}
								isSubmitting={isSubmitting}
								setStep={setStep}
								nextStep={2}
								isValid={isValid}
								claims={claims}
								userNicknames={userNicknames}
							/>
						)}
						{step === 2 && (
							<ReviewStep
								control={control}
								watch={watch}
								register={register}
								questions={getQuestionsByGroup("about-product-characteristics")}
								group="about-product-characteristics"
								setValue={setValue}
								setStep={setStep}
								nextStep={3}
								prevStep={1}
							/>
						)}
						{step === 3 && (
							<ReviewStep
								control={control}
								watch={watch}
								register={register}
								questions={getQuestionsByGroup("about-product-taste-and-scent")}
								group="about-product-taste-and-scent"
								setValue={setValue}
								setStep={setStep}
								nextStep={4}
								prevStep={2}
							/>
						)}
						{step === 4 && (
							<ReviewStep
								control={control}
								watch={watch}
								register={register}
								questions={getQuestionsByGroup("about-product-effects")}
								group="about-product-effects"
								setValue={setValue}
								setStep={setStep}
								isLastStep={true}
							/>
						)}
					</form>
				) : (
					<div className="w-full bg-white py-9 rounded-md text-center font-semibold">
						טוען...
					</div>
				)}
			</div>
		</ModalDialog>
	);
};

export default ReviewModal;
