import { ClipboardCheckIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import React from "react";
//* not in use, can be removed
function FinalStep({ close }) {
	return (
		<div className="text-center">
			<div className="rounded-md mb-2 flex flex-col w-full items-center mx-auto">
				<ClipboardCheckIcon className="w-20 h-20 mb-5" />
				<p className="text-3xl font-semibold inline max-w-xs mb-3">
					הביקורת נשלחה, תודה רבה שעזרת לקהילה!
				</p>
			</div>

			<div className="flex gap-2 justify-center mt-2 mb-2">
				<Button
					primary
					onClick={() => {
						close();
					}}
				>
					סיום
				</Button>
			</div>
		</div>
	);
}

export default FinalStep;
