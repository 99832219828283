import { EmptyPlaceholder } from "components/tables/EmptyPlaceholder";
import { TablePagination } from "components/tables/server-table/TablePagination";
import { useTable, usePagination, useSortBy } from "react-table";
import { classNames } from "util/common";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { ViewGridAddIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useMemo, useState } from "react";

export function Table({
	items,
	columns,
	loading = false,
	withEmptyPlaceholder = false,
	withCustomEmptyPlaceholder = false,
	onAddClick = () => false,
	emptyPlaceholderText,
	selectable = false,
	selectedIndexes: selectedIndexesProp,
	onSelectionChanged = selItems => {},
	hidePagination = false,
	initialPageSize = 10,
	getRowClassName = r => "",
	getRowHiddenContent = r => null,
	disableMultiSort = false,
	disableSortBy = false,
}) {
	const [selectedIndexes, setSelectedIndexes] = useState(
		selectedIndexesProp || [],
	);
	const [expandedRowId, setExpandedRowId] = useState(null);
	const isRowSelected = idx => selectedIndexes.indexOf(idx) !== -1;

	useEffect(() => {
		if (!Array.isArray(selectedIndexesProp)) {
			return;
		}
		setSelectedIndexes(selectedIndexesProp || []);
	}, [selectedIndexesProp]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data: items,
			initialState: { pageIndex: 0, pageSize: initialPageSize },
			disableMultiSort,
			disableSortBy,
		},
		useSortBy,
		usePagination,
	);

	const noItemsMessage = () => {
		if (!!loading) {
			return "טוען ...";
		}
		if (!!withEmptyPlaceholder) {
			return (
				<EmptyPlaceholder
					text={emptyPlaceholderText}
					icon={<ViewGridAddIcon className="w-6 h-6" />}
					onClick={onAddClick}
				/>
			);
		} else if (!!withCustomEmptyPlaceholder) {
			return (
				<EmptyPlaceholder
					isHasBorder={false}
					text={emptyPlaceholderText}
					icon={<ShoppingCartIcon className="w-6 h-6" />}
					onClick={onAddClick}
				/>
			);
		}
		return "אין נתונים להצגה.";
	};

	const hasItems = !!items && !!items.length;
	const isAllSelected = selectedIndexes.length === items.length;

	const onRowSelectionChanged = (rowIdx, isSelected) => {
		let newIndexes = selectedIndexes.filter(i => i !== rowIdx);
		if (!!isSelected) {
			newIndexes.push(rowIdx);
		}
		setSelectedIndexes(newIndexes);
		onSelectionChanged(newIndexes);
	};

	const onHeaderSelectionChanged = isSelected => {
		const newIndexes = !!isSelected ? [...Array(items.length).keys()] : [];
		setSelectedIndexes(newIndexes);
		onSelectionChanged(newIndexes);
	};

	const onRowClick = r => {
		const hiddenContent = getRowHiddenContent(r);
		if (!hiddenContent) {
			return;
		}
		if (expandedRowId === r.id) {
			setExpandedRowId(null);
			return;
		}
		setExpandedRowId(r.id);
	};

	const getNumCells = row => {
		let ret = row.cells.length;
		if (!!selectable) {
			ret++;
		}
		const hiddenContent = getRowHiddenContent(row?.original);
		if (!!hiddenContent) {
			ret++;
		}
		return ret;
	};

	return (
		<>
			{!hasItems && <div className="text-jane-500">{noItemsMessage()}</div>}

			{!!hasItems && (
				<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative">
					<div className="align-middle min-w-full overflow-x-auto">
						<table
							{...getTableProps()}
							className="min-w-full divide-y divide-gray-300 bg-gray-50"
						>
							<thead className="select-none whitespace-nowrap">
								{headerGroups.map((headerGroup, gi) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={gi}>
										{!!selectable && (
											<th className="px-2 sm:px-3 text-center" key={`${gi}s`}>
												<input
													type="checkbox"
													className="focus:ring-jane-500 h-4 w-4 accent-jane-500 border-gray-300 rounded cursor-pointer"
													onChange={e =>
														onHeaderSelectionChanged(!!e.target.checked)
													}
													checked={isAllSelected}
												/>
											</th>
										)}

										{headerGroup.headers.map((column, hi) => (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps(),
												)}
												key={hi}
												scope="col"
												className={classNames(
													"pr-1 md:pr-5 lg:pr-[22px] text-start text-sm font-semibold",
													!!column.isSorted
														? "underline text-jane-900"
														: "text-jane-700",
												)}
											>
												<div className="flex items-center">
													{column.render("Header")}
													{!!column.isSorted ? (
														!!column.isSortedDesc ? (
															<ChevronDownIcon className="w-4 h-4 mr-1 text-jane-500" />
														) : (
															<ChevronUpIcon className="w-4 h-4 mr-1 text-jane-500" />
														)
													) : (
														""
													)}
												</div>
											</th>
										))}
									</tr>
								))}
							</thead>

							<tbody
								{...getTableBodyProps()}
								className="divide-y divide-gray-200 bg-white"
							>
								{page.map((row, ri) => {
									prepareRow(row);
									const isSelected = isRowSelected(ri);
									const hiddenContent = getRowHiddenContent(row?.original);
									const isExpanded = expandedRowId === row?.original?.id;
									return (
										<Fragment key={ri}>
											<tr
												{...row.getRowProps()}
												className={classNames(
													"group",
													!!isSelected
														? "bg-blue-50 bg-opacity-75 hover:bg-opacity-100"
														: "hover:bg-gray-50",
													!!hiddenContent ? "cursor-pointer" : "",
													!!isExpanded
														? "bg-yellow-50 hover:bg-yellow-50 bg-opacity-50 hover:bg-opacity-75"
														: "",
													getRowClassName(row?.original, isSelected),
												)}
											>
												{!!selectable && (
													<td
														className="px-2 sm:px-3 text-center"
														key={`${ri}s`}
													>
														<input
															type="checkbox"
															className="focus:ring-jane-500 h-4 w-4 accent-jane-500 border-gray-300 rounded cursor-pointer"
															onChange={e =>
																onRowSelectionChanged(ri, !!e.target.checked)
															}
															checked={isSelected}
															onClick={e => e.stopPropagation()}
														/>
													</td>
												)}

												{row.cells.map((cell, ci) => {
													return (
														<td
															{...cell.getCellProps()}
															key={ci}
															className="whitespace-nowrap px-1 py-1 sm:px-3 text-sm text-gray-500"
															onClick={() =>
																cell.column.id !== "status" &&
																onRowClick(row?.original)
															}
														>
															{cell.render("Cell")}
														</td>
													);
												})}

												{!!hiddenContent && (
													<td>
														{!!isExpanded ? (
															<ChevronUpIcon
																className="w-5 h-5 text-gray-500"
																onClick={() => onRowClick(row?.original)}
															/>
														) : (
															<ChevronDownIcon
																className="w-5 h-5 text-gray-400"
																onClick={() => onRowClick(row?.original)}
															/>
														)}
													</td>
												)}
											</tr>
											{!!isExpanded && !!hiddenContent && (
												<tr>
													<td colSpan={getNumCells(row)} className="p-0">
														<div className="shadow-inner bg-gray-50 px-4 py-2.5">
															{hiddenContent}
														</div>
													</td>
												</tr>
											)}
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</div>
					{!hidePagination && pageCount > 1 && (
						<div>
							<TablePagination
								pageCount={pageCount}
								pageIndex={pageIndex}
								pageSize={pageSize}
								canPreviousPage={canPreviousPage}
								canNextPage={canNextPage}
								onNextClicked={nextPage}
								onPrevClicked={previousPage}
								onPageNumClicked={pn => gotoPage(pn - 1)}
								onPageSizeChanged={ps => setPageSize(ps)}
								disabled={!!loading}
							/>
						</div>
					)}
					{!!loading && (
						<div className="absolute bg-white top-0 left-0 w-full h-full rounded-md bg-opacity-40" />
					)}
				</div>
			)}
		</>
	);
}
