import { PlusIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReviewModal from "components/modals/ReviewModal";
import { AuthContext } from "components/contexts/AuthContext";
import { useApi } from "components/hooks/useApi";
import { Tooltip } from "react-tippy";
import { useRouter } from "next/router";
import { toastMessage } from "util/common";

const ReviewButton = ({
	product,
	isSingleProductPage,
	setIsLoginOpen,
	onAfterSubmit = () => {},
	isPrimaryButton = true,
}) => {
	const [openReviewModal, setOpenReviewModal] = useState(false);
	const [submittedReview, setSubmittedReview] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [batch, setBatch] = useState(null);
	const [notReviewedBatches, setNotReviewedBatches] = useState(null);
	const [notReviewedLoading, setNotReviewedLoading] = useState();
	const { claims, authenticated } = useContext(AuthContext);
	const api = useApi();
	const router = useRouter();

	const getUserNotReviewedBatches = async () => {
		if (!!notReviewedLoading) {
			return;
		}
		try {
			setNotReviewedLoading(true);
			const res = await api.getUserNotReviewedBatches(product.id);
			//setApiError(null);
			if (res.ok) {
				setNotReviewedBatches(res.data.not_reviewed_batches);
				// If user has not reviewed batches he can leave review
				if (res.data.not_reviewed_batches.length > 0) {
					setIsVerified(true);
				}
				if (res.data.not_reviewed_batches.length === 1) {
					setBatch(res.data.not_reviewed_batches[0]);
				}
			}
		} catch (error) {
			///setApiError(res.error);
		} finally {
			setNotReviewedLoading(false);
		}
	};

	const resetPath = () => {
		if (isSingleProductPage) {
			router.push(router.asPath.split("?")[0], undefined, { shallow: true });
		}
	};

	useEffect(() => {
		// Make sure that notReviewedBatches loaded and user is authenticated
		if (!authenticated || notReviewedLoading || notReviewedBatches === null)
			return;

		const queryParams = router.query;

		if (queryParams.rtr && queryParams.b) {
			// If no batch review:
			if (
				queryParams.b == "noBatch" &&
				notReviewedBatches.some(batch => batch.batch_id == "noBatch")
			) {
				setBatch(null);
				setOpenReviewModal(true);
				resetPath();
				return;
			}
			// If user can't leave a review for batch from query params:
			else if (!notReviewedBatches.some(batch => batch.id == queryParams.b)) {
				toastMessage("לא ניתן להשאיר ביקורת עבור האצווה הזו", "error");
				resetPath();
				return;
			}
			// User can leave review for batch from query params:
			else {
				setBatch(
					notReviewedBatches.find(batch => batch.batch_id === queryParams.b),
				);
				setOpenReviewModal(true);
				resetPath();
				return;
			}
		}
	}, [authenticated, notReviewedBatches]);

	useEffect(() => {
		const queryParams = router.query;
		if (!authenticated && queryParams.rtr && queryParams.t) {
			setIsLoginOpen(true);
		}
		if (authenticated) {
			setIsLoginOpen(false);
			getUserNotReviewedBatches();
		}
	}, [authenticated]);

	return (
		<>
			{openReviewModal && (
				<ReviewModal
					product={product}
					open={openReviewModal}
					setOpen={setOpenReviewModal}
					paramBatch={batch}
					onSubmit={() => {
						onAfterSubmit();
					}}
					onClose={() => {
						setOpenReviewModal(false);
						setBatch(null);
						resetPath();
					}}
					batches={notReviewedBatches}
					setParamBatch={setBatch}
					setSubmittedReview={setSubmittedReview}
				/>
			)}
			<Tooltip
				title={!isVerified ? "ניתן להוסיף ביקורת לאחר השלמת הזמנה" : undefined}
				disabled={authenticated && isVerified}
				placement="top"
				arrow
			>
				<Button
					onClick={() => setOpenReviewModal(true)}
					className="gap-1 flex"
					disabled={!isVerified || !authenticated}
					primary={isPrimaryButton}
				>
					<span className="whitespace-nowrap">הוסף ביקורת</span>
					<span>
						<PlusIcon
							className={`w-3 h-3 ${
								isPrimaryButton ? "text-white" : "text-jane-800"
							}`}
						/>
					</span>
				</Button>
			</Tooltip>
		</>
	);
};

export default ReviewButton;
