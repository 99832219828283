import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { Controller } from "react-hook-form";

const StarRating = ({ name, control }) => {
	return (
		<div className="flex items-center justify-start gap-x-1">
			{[1, 2, 3, 4, 5].map(starValue => (
				<Controller
					key={starValue}
					name={name}
					rules={{
						required: true,
					}}
					control={control}
					render={({ field: { onChange, value } }) => (
						<label key={starValue} className="cursor-pointer">
							<input
								type="radio"
								className="hidden"
								value={starValue}
								onChange={e => onChange(e.target.value.toString())}
								checked={String(value) === String(starValue)}
							/>
							<StarIcon
								className={`w-8 h-8 transition-all ${
									Number(value) >= Number(starValue)
										? "text-yellow-500"
										: "text-gray-300"
								}`}
							/>
						</label>
					)}
				/>
			))}
		</div>
	);
};

export default StarRating;
