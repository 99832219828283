import { XCircleIcon, XIcon } from "@heroicons/react/outline";
import { useHttp } from "components/hooks/useHttp";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

const FileUploadForm = ({ amount = [1, 2, 3, 4, 5], setValue, watch }) => {
	const [activated, setActivated] = useState(null);
	const [loading, setLoading] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	});
	const [errors, setErrors] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	});
	const { $upload } = useHttp();
	const changeMultipleFiles = async e => {
		if (e.target.files) {
			const imageArray = Array.from(e.target.files);
			const promises = [];
			for (let i = 0; i < imageArray.length; i++) {
				if ((imageArray[i].size / 1024).toFixed(2) > 7000) {
					setErrors(prev => ({ ...prev, [i + 1]: true }));
					return;
				} else {
					setErrors(prev => ({ ...prev, [i + 1]: false }));
				}
				if (activated + i > 5) return;
				setLoading(prev => {
					return { ...prev, [activated + i]: true };
				});
				const res = $upload(imageArray[i], "reviews");
				promises.push(res);
			}
			await Promise.all(promises)
				.then(res => {
					res.map((r, i) => {
						setValue(`images.${activated + i}`, {
							image_url: r.data.url,
							thumbnail_url: r.data.product_thumbnail_url,
						});
					});
				})
				.catch(e => {
					console.warn(e.message);
				})
				.finally(() => {
					setLoading({
						1: false,
						2: false,
						3: false,
						4: false,
						5: false,
					});
				});
		}
	};

	const removeImage = indexToRemove => {
		const updatedImages = watch("images").filter(
			(_, index) => index !== indexToRemove,
		);
		setValue("images", updatedImages);
	};

	return (
		<div>
			<div>
				<input
					className="hidden"
					type="file"
					id="images"
					onChange={e => {
						changeMultipleFiles(e);
					}}
					multiple
					accept="image/png, image/jpeg, image/jpg"
				/>
			</div>
			<div className="flex gap-5 flex-wrap">
				{!!watch("images") &&
					watch("images").map((img, index) => (
						<div className="relative" key={index}>
							<img
								src={watch("images")[index]?.image_url}
								className="w-24 h-24 rounded-md cursor-pointer"
								onClick={() => {
									setActivated(index);
								}}
							/>
							<div
								className="absolute top-0"
								onClick={e => {
									removeImage(index);
								}}
							>
								<XCircleIcon className="w-7 h-7 bg-white rounded-full cursor-pointer" />
							</div>
						</div>
					))}
				{watch("images")?.length < 5 && (
					<div className="relative">
						<label
							htmlFor="images"
							className="text-black text-xl cursor-pointer"
							onClick={() => {
								setActivated(watch("images").length);
							}}
						>
							{loading[watch("images").length] ? (
								<div className="w-24 h-24 flex justify-center items-center text-sm rounded-md border-dashed border-2 bg-jane-200 animate-pulse">
									טוען...
								</div>
							) : (
								<div className="w-24 h-24 flex flex-col justify-center items-center text-xs text-jane-500 rounded-md border-dashed border-2 transition-colors hover:bg-jane-200">
									<MdOutlineAddPhotoAlternate className="w-9 h-9" />
									<span>העלאת תמונה</span>
								</div>
							)}
						</label>
					</div>
				)}
			</div>
			{Object.values(errors).some(e => e) && (
				<span className="text-sm text-red-500 mt-1">
					ניתן להעלות קבצים עד 7MB
				</span>
			)}
		</div>
	);
};

export default FileUploadForm;
